<template>
  <div>
    <div class="section-title">
      <h3>{{ $t('order.title')  }}</h3>
    </div>
    <div class="activity-items">
      <div v-if="!patient.id">
        <select-patient
            @select="prepareComponent"
            :patient.sync="patient"
            class="columns"></select-patient>
      </div>
      <div v-if="patient.id" >
        <p>
          <strong>Paciente:</strong> {{patient.code}}<br>
          <strong>Nombre:</strong> {{patient.full_name}}<br>
        </p>
        <b-button @click="patient={}; params.patient = null; prepareComponent()">Elegir Otro Paciente</b-button>
      </div>
      <form method="get" @submit.prevent="prepareComponent(); showModal = true" class="block">
        <br>
        <b-field grouped group-multiline>
          <b-field label="Año"
                   label-position="on-border">
            <b-select v-model="params.year">
              <option
                  v-for="item in yearsRange"
                  :value="item.value"
                  :key="item.value">
                {{ item.text }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Orden"
                   label-position="on-border">
            <b-input v-model="order"
                     @keypress.enter.native="prepareComponent">
            </b-input>
          </b-field>
          <select-company label="Empresa"
                          v-if="params.type === 'only_company'"
                          :saved="{}"
                          @select="onSelectCompany"/>
          <b-field label="Fecha"
                   label-position="on-border">
            <b-datepicker v-model="params.date"
                          :max-date="new Date()"
                          @keypress.enter.native="prepareComponent">
            </b-datepicker>
          </b-field>
          <b-field>
            <b-field>
              <b-radio v-model="params.type"
                       name="type"
                       native-value="all">
                Todas
              </b-radio>
              <b-radio v-model="params.type"
                       name="type"
                       native-value="only_company">
                Empresas
              </b-radio>
              <b-radio v-model="params.type"
                       name="type"
                       native-value="only_patient">
                Particular
              </b-radio>
            </b-field>
          </b-field>
          <b-field>
            <p class="control">
              <button class="button is-primary" type="submit">
                <b-icon icon="search"></b-icon>
              </button>
            </p>
          </b-field>
        </b-field>
      </form>
    </div>
    <b-modal v-model="showModal">
      <iframe class="lapi__iframe" :src="reportUrl"></iframe>
    </b-modal>
  </div>
</template>

<script>
import OrderLoading from "../../components/order/OrderLoading";
import OrderListItem from "../../components/order/OrderListItem";
import SelectCompany from "@/components/company/SelectCompany";
import SelectPatient from "@/components/patient/SelectPatient";
export default {
  name: "OrdersDay",
  components: {SelectCompany, OrderListItem, OrderLoading, SelectPatient},
  props: {
    showDateRange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: [],
      apiUrl: 'orders',
      loading: true,
      total: 0,
      order: '',
      params: {
        year: this.$store.state.main.year,
        branch_office: this.$auth.user().active_branch_office,
        type: 'all',
        date: new Date()
      },
      patient: {},
      showModal: false
    }
  },
  computed: {
    yearsRange() {
      const current = new Date().getFullYear();
      const years = [];
      for(let i = 0;i < 3;i++){
        years.push({value: ((current-i) % 10) , text: (current-i) });
      }
      return years;
    },
    reportUrl() {
      const  url = new URL(`${process.env.VUE_APP_API_URL}/reports/day-orders`);
      for(const i in this.params){
        if(i === 'date'){
          url.searchParams.append(i, this.$alfalab.formatDate(this.params[i]));
        }else{
          url.searchParams.append(i, this.params[i]);
        }

      }
      return url.href;
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      if (this.order !== '') {
        this.params.order = String(this.params.year) + this.order.padStart(7);
      }
      if(this.patient.code){
        this.params.patient = this.patient.code;
      }
    },
    onSelectCompany(company) {
      this.params.company = company.company;
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
